import React, { createContext, useReducer } from 'react';
import { Reducer, ConsoleLoggerReducer } from './reducer';
import { UrlUtil } from './shared/domain/utils/urlUtil';
import { context as userContext } from './user/domain/context';

export const initialState = {
  isE2ETesting: UrlUtil.getQueryParameterByName('e2e') === 'true',
  locale: UrlUtil.getQueryParameterByName('locale') || '',
  userContext,
};

export const Context = createContext(initialState);

export const Store = ({ children }) => {
  const reducer = process.env.NODE_ENV === 'development' ? ConsoleLoggerReducer : Reducer;
  const [state, dispatch] = useReducer(reducer, initialState);

  return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>;
};
