import { actions } from './actionType';

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.LOGGED_IN:
      return {
        ...state,
        userName: action.payload && (action.payload.cdsid || action.payload.sub),
        retailerId: action.payload && action.payload.ccc_retailerId,
        language: action.payload && action.payload.preference.language,
      };
    case actions.AUTH_ERROR:
      return {};
    default:
      return state;
  }
};
