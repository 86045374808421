import { useContext } from 'react';
import {
  GET_BOOKING_EVENTS_BY_RETAILER_QUERY,
  GET_BOOKINGS_BY_RETAILER_QUERY,
  SUBSCRIPTION_BOOKING_STATUS_QUERY,
  SUBSCRIPTION_NEW_BOOKING_CREATED_QUERY,
  SUBSCRIPTION_BOOKING_CANCELLED_QUERY,
  SUBSCRIPTION_BOOKING_WORK_LIST_UPDATED_QUERY,
} from './queries';
import { useSubscription } from '@apollo/client';
import { Context } from '../../store';
import { useAuthenticatedQuery } from '../../authentication/useAuthenticatedQuery';

export const useGetBookingEventsByRetailerQuery = () => {
  const result = useAuthenticatedQuery(GET_BOOKING_EVENTS_BY_RETAILER_QUERY);

  return result;
};

export const useGetBookingsByRetailerQuery = () => {
  const [{ userContext }] = useContext(Context);
  const { retailerId } = userContext;
  const result = useAuthenticatedQuery(GET_BOOKINGS_BY_RETAILER_QUERY, {
    variables: { retailerId },
    skip: !retailerId,
  });

  return result;
};

export const useBookingStatusSubscriptionQuery = (retailerId) => {
  const { data } = useSubscription(SUBSCRIPTION_BOOKING_STATUS_QUERY, { variables: { retailerId } });
  return data?.bookingStatus;
};

export const useBookingWorkListSubscriptionQuery = (retailerId) => {
  const { data } = useSubscription(SUBSCRIPTION_BOOKING_WORK_LIST_UPDATED_QUERY, { variables: { retailerId } });
  return data?.bookingWorkListUpdated;
};

export const useNewBookingCreatedSubscriptionQuery = (retailerId) => {
  const { data } = useSubscription(SUBSCRIPTION_NEW_BOOKING_CREATED_QUERY, { variables: { retailerId } });
  return data?.bookingCreated;
};

export const useBookingCancelledSubscriptionQuery = (retailerId) => {
  const { data } = useSubscription(SUBSCRIPTION_BOOKING_CANCELLED_QUERY, { variables: { retailerId } });
  return data?.bookingCancelled;
};
