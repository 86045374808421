import gql from 'graphql-tag';

export const GET_AUTH_CLAIMS = gql`
  query GetAuthClaims {
    me {
      id
      cdsid
      displayname
      sub
      ccc_role
      ccc_markets
      ccc_org
      preference {
        language
      }
      ccc_retailerId
    }
  }
`;
