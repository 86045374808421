import React, { useState, useEffect } from 'react';
import { HorizontalLayout, VerticalLayout } from '@vcc-gcs/bookingengine-shared-frontend';
import { Checkbox, Text } from 'vcc-ui';
import styled from 'styled-components';
import { formattedDate } from '../../../shared/domain/utils/dateUtils';
import { Head } from './head/head';
import { EventTypeDetailsCard } from './body/EventTypeDetailsCard';
import { Work } from '../work/Work';

import { CustomerInfoCard } from '../customer-info/CustomerInfoCard';
import { DateTimeCard } from '../date-time/DateTimeCard';
import { useTranslation } from 'react-i18next';

import { ServicePlanCard } from '../service-plan/ServicePlanCard';

const StyledHorizontalLayout = getStyleHorizontalLayout();
const StyledHandler = getStyledHandler();

const Event = ({ event = {}, eventNumber, booking = {}, handleEvent, marketData }) => {
  const { t, i18n } = useTranslation();

  const [showContent, setShowContent] = useState(event?.state === 'unhandled' ? true : false);
  const [checked, setChecked] = useState(false);
  const handleChecked = (e) => {
    setChecked(e.currentTarget.checked);
  };

  const { appointmentDetails, timeslots, id: bookingId } = booking;
  const {
    dropoffOptionObj,
    dropoffOptionTranslated,
    subDropoffOptionsObj,
    primaryServices,
    additionalOptions,
    servicePrice,
    customerInformation,
    pickupAddress,
    servicePlan,
    servicePlanAdditionalInfo,
  } = appointmentDetails || {};

  const { created, type, state } = event;
  const handleStatementType = type === 'cancelled' ? 'handleStatementcancelled' : 'handleStatement';

  useHanldeEventEffect(handleEvent, checked, event, bookingId);

  const servicePlanObject = servicePlan && marketData?.market?.servicePlans?.find((sp) => sp.id === servicePlan);

  return (
    <StyledHorizontalLayout showContent={showContent} spacing={10}>
      <Text className={'event-date'}>{formattedDate(created, i18n.language)}</Text>
      <Text className={'event-order'}>{eventNumber.toString().padStart(2, '0')}</Text>
      <VerticalLayout style={{ width: '75%', alignSelf: 'flex-start' }}>
        <Head event={event} eventNumber={eventNumber} show={{ showContent, setShowContent }} />
        {showContent && (
          <>
            {state === 'unhandled' && (
              <HorizontalLayout id={'bookingContent-handle'} style={{ margin: '1em 0em' }} className={'event-state'}>
                <Text>{t(`appointments.eventType.appointment.${handleStatementType}`)}</Text>
                <StyledHandler>
                  <Checkbox
                    data-test-id={'handle-event-checkbox'}
                    checked={checked}
                    onChange={(e) => handleChecked(e)}
                  />
                  <Text className={'handle-text'} extend={{ color: '#FFFFFF' }}>
                    {t(`appointments.eventType.appointment.markHandled`)}
                  </Text>
                </StyledHandler>
              </HorizontalLayout>
            )}

            {eventNumber === 1 && (
              <VerticalLayout style={{ width: '90%', flex: '1 0', alignSelf: 'flex-end' }}>
                {servicePlanObject && (
                  <ServicePlanCard
                    servicePlan={servicePlanObject}
                    servicePlanAdditionalInfo={servicePlanAdditionalInfo}
                  />
                )}
                <DateTimeCard
                  pickupAddress={pickupAddress}
                  dropoffOptionObj={dropoffOptionObj}
                  dropoffOptionTranslated={dropoffOptionTranslated}
                  subDropoffOptionsObj={subDropoffOptionsObj}
                  timeslots={timeslots}
                />
                <Work
                  className="work"
                  primaryServices={primaryServices}
                  additionalOptions={additionalOptions}
                  servicePrice={servicePrice}
                  currencySymbol={marketData?.market?.currencySymbol}
                />
                {customerInformation && <CustomerInfoCard customerInformation={customerInformation} />}
              </VerticalLayout>
            )}
            <VerticalLayout style={{ width: '90%', flex: '1 0', alignSelf: 'flex-end' }}>
              <EventTypeDetailsCard event={event} booking={booking} />
            </VerticalLayout>
          </>
        )}
      </VerticalLayout>
    </StyledHorizontalLayout>
  );
};

function useHanldeEventEffect(handleEvent, checked, { eventId, type, state }, bookingId) {
  useEffect(() => {
    if (checked) {
      handleEvent({
        eventId,
        bookingId,
        state,
        type,
      });
    }
  }, [checked, handleEvent, bookingId, eventId, state, type]);
}

function getStyledHandler() {
  return styled(HorizontalLayout)`
    margin: 0px 10px;
    display: flex;
    flex: 1 0 25%;
    background-color: #177e2e;
    border-radius: 5px;
    padding: 1em;
    .handle-text {
      padding: 0px 0.5em;
      align-items: center;
      font-size: 1em;
      font-family: Arial;
    }
  `;
}

function getStyleHorizontalLayout() {
  return styled(HorizontalLayout)`
    margin-left: 2em;
    font-size: 16px;
    align-items: flex-start;
    .event-order {
      background-color: ${(props) => (props.showContent ? '#1CA2FF' : '#f4f8fe')};
      color: ${(props) => (props.showContent ? '#f4f8fe' : '#1ca2ff')};
      border-radius: 20px;
      border: 2px solid rgba(0, 150, 255, 0.66);
      margin: 0px 5px;
      width: 2em;
      height: 2em;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.75em;
      font-family: Arial;
      align-self: flex-start;
    }

    .event-date {
      font-size: 0.85em;
      font-family: Arial;
      align-self: flex-start;
    }

    .event-state {
      margin: 2em 0em;
      padding: 1em;
      border: 1px dashed rgba(0, 95, 205, 0.35);
      background: #e3f3ff;
      box-sizing: border-box;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.75em;
      font-family: Arial;
    }

    .date-time,
    .work,
    .customercomments,
    .creationInfo {
      padding: 1em;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.12);
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 0.75em;
      font-family: Arial;
      flex: 1 0 90%;
    }
    .work {
      margin: 0.5em 0em;
    }

    .date-time-content,
    .customercomments-content,
    .creationInfo-content {
      display: flex;
      flex-grow: 1;
    }

    .date-time-content li {
      list-style: circle;
    }

    .work-content li {
      list-style: circle;
    }
    .customercomments-text {
      padding: 1em;
      background: #f2f7fa;
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: Arial;
    }
  `;
}

export { Event };
