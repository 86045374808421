import React, { useContext, useEffect } from 'react';
import { GET_AUTH_CLAIMS } from './domain/authQueries';
import { useLazyQuery } from '@apollo/client';
import { Context } from '../store';
import { Loader } from '@vcc-gcs/bookingengine-shared-frontend';

const AuthWrapper = (props) => {
  const [{ locale, userContext }, dispatch] = useContext(Context);
  const [, { data, error, loading, refetch }] = useLazyQuery(GET_AUTH_CLAIMS, {
    context: locale && {
      userName: userContext.userName,
      headers: {
        'accept-language': locale,
      },
    },
  });
  const { me } = data || {};

  useEffect(() => {
    if (userContext.userName) return;
    refetch();
  }, [refetch, userContext.userName]);

  useEffect(() => me && dispatch({ type: 'LOGGED_IN', payload: me }), [dispatch, me]);
  useEffect(() => {
    if (error && error.graphQLErrors)
      error.graphQLErrors.forEach((gqlErr) => {
        const { extensions } = gqlErr;
        if (
          extensions.code === 'UNAUTHENTICATED' ||
          extensions.code === 'UNAUTHORIZED_ACCESS' ||
          extensions.code === 'FORBIDDEN'
        ) {
          dispatch({ type: 'AUTH_ERROR', payload: 'Auth_error' });
        }
      });
  }, [dispatch, error]);
  return (
    <Loader loading={loading}>
      {!error && data && props.children}
      {error && <>Please refresh your session</>}
    </Loader>
  );
};

export { AuthWrapper };
