import { reducer as userReducer } from './user/domain/reducer';

export const Reducer = (state, action) => {
  const nextState = ApplicationReducer(state, action);

  return {
    ...nextState,
    userContext: userReducer(state.userContext, action),
  };
};

export const ConsoleLoggerReducer = (state, action) => {
  const nextState = Reducer(state, action);
  console.log('previous state: ', state);
  console.log('action: ', action.type, action);
  console.log('next state: ', nextState);
  return nextState;
};

function ApplicationReducer(state, action) {
  switch (action.type) {
    default:
      return state;
  }
}
