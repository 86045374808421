import React, { useState } from 'react';
import { Block, Nav, NavItem, TextInput } from 'vcc-ui';
import { VerticalLayout, HorizontalLayout, Loader, Translation, icons } from '@vcc-gcs/bookingengine-shared-frontend';
import styled from 'styled-components';
import { BookingsList } from './appointment-list/BookingsList';
import { TodoList } from './appointment-list/TodoList';
import { TodoListHeader, BookingsListHeader, size as headerSize } from './headers-list/ListHeader';
import { AppointmentTimeline } from '../appointment-timeline/AppointmentTimeline';
import { tab } from './domain/tab';

const StyledVerticalLayout = getStyledVerticalLayout();
const StyledVerticalDetailLayout = getStyledVerticalDetailLayout();

const AppointmentsInboxView = ({
  bookingEvents,
  bookings,
  selectedBookingId,
  loading,
  sortByColumnHeader,
  sortColumn,
  onChangeTab,
  selectedTab,
  columnsSortOrderMap,
  handleSearch,
  retailerId,
  onHandleSetSelectedBooking,
  bookingWorkListUpdated,
}) => {
  const handleCloseAppointmentDetails = () => {
    onHandleSetSelectedBooking();
  };

  return (
    <Loader loading={loading}>
      <HorizontalLayout style={{ margin: '0.5em' }}>
        <StyledVerticalLayout
          className={'area'}
          id={'tabbedSection'}
          style={{ width: manageWidthTabSection(selectedBookingId) }}
        >
          <Navs
            selectedTab={selectedTab}
            tabChange={onChangeTab}
            count={bookingEvents && bookingEvents.length}
            handleSearch={handleSearch}
            displaySearch={selectedTab === tab.ALL_BOOKINGS && !selectedBookingId}
          />
          {selectedTab === tab.TODO ? (
            <TodoTab
              selectedBookingId={selectedBookingId}
              bookingEvents={bookingEvents}
              sortByColumnHeader={sortByColumnHeader}
              sortColumn={sortColumn}
              setBookingId={onHandleSetSelectedBooking}
              columnsSortOrderMap={columnsSortOrderMap}
            />
          ) : (
            <BookingsTab
              selectedBookingId={selectedBookingId}
              setBookingId={onHandleSetSelectedBooking}
              bookings={bookings}
              sortByColumnHeader={sortByColumnHeader}
              sortColumn={sortColumn}
              columnsSortOrderMap={columnsSortOrderMap}
            />
          )}
        </StyledVerticalLayout>
        {selectedBookingId && (
          <StyledVerticalDetailLayout className={'detailArea'}>
            <AppointmentTimeline
              retailerId={retailerId}
              bookingId={selectedBookingId}
              closeAppointmentDetails={handleCloseAppointmentDetails}
              bookingWorkListUpdated={bookingWorkListUpdated}
            />
          </StyledVerticalDetailLayout>
        )}
      </HorizontalLayout>
    </Loader>
  );
};

const navStyle = {
  borderRadius: 20,
  backgroundColor: '#E87722',
  color: 'white',
  margin: '0px',
  minWidth: '18px',
  maxWidth: '25px',
  height: 'auto',
  textAlign: 'center',
  fontSize: '12px',
  marginLeft: '5px',
  padding: '2px',
};

const Navs = ({ selectedTab, tabChange, count, displaySearch, handleSearch }) => {
  const [searchValue, setSearchValue] = useState('');
  const handleSearchBoxSearch = (value) => {
    setSearchValue(value);
    handleSearch(value);
  };
  return (
    <Block style={{ display: 'flex', alignItems: 'end' }}>
      <Nav>
        <NavItem
          data-test-id={'todoTab'}
          isActive={selectedTab === tab.TODO}
          onClick={() => tabChange(tab.TODO)}
          className={'nav-item'}
        >
          <HorizontalLayout>
            <div data-test-id={'todoLabel'}>
              <Translation textId="appointments.newevents" />
            </div>
            <Block data-test-id={'todoTabCount'} extend={navStyle}>
              {count}
            </Block>
          </HorizontalLayout>
        </NavItem>
        <NavItem
          data-test-id={'allBookingsTab'}
          isActive={selectedTab === tab.ALL_BOOKINGS}
          onClick={() => tabChange(tab.ALL_BOOKINGS)}
          className={'nav-item'}
        >
          <Translation textId="appointments.handled" />
        </NavItem>
      </Nav>
      {displaySearch && <SearchBox value={searchValue} handleSearch={handleSearchBoxSearch} />}
    </Block>
  );
};

const SearchBox = ({ value, handleSearch, ...restProps }) => {
  const [currentValue, setValue] = useState(value);

  const handleSetValue = (value) => {
    setValue(value);
    if (value && value.length > 2) {
      handleSearch(value.toLowerCase());
    } else {
      handleSearch('');
    }
  };

  return (
    <HorizontalLayout spacing={8} style={{ padding: '0 10px', alignItems: 'center' }} {...restProps}>
      <TextInput
        style={{ width: '400px' }}
        value={currentValue || ''}
        onChange={(event) => handleSetValue(event.target.value)}
        data-test-id="booking-search-input"
      />
      <icons.SearchIcon fill={'darkgrey'} style={{ marginLeft: -40 }} />
    </HorizontalLayout>
  );
};

const TodoTab = ({
  bookingEvents,
  sortByColumnHeader,
  selectedBookingId,
  setBookingId,
  sortColumn,
  columnsSortOrderMap,
}) => {
  return (
    <>
      <TodoListHeader
        onColumnHeaderClick={sortByColumnHeader}
        headerSize={selectedBookingId ? headerSize.SMALL : headerSize.LARGE}
        sortColumn={sortColumn}
        columnsSortOrderMap={columnsSortOrderMap}
      />
      <TodoList bookingEvents={bookingEvents} setBookingId={setBookingId} selectedBookingId={selectedBookingId} />
    </>
  );
};

const BookingsTab = ({
  bookings,
  sortByColumnHeader,
  selectedBookingId,
  setBookingId,
  sortColumn,
  columnsSortOrderMap,
}) => {
  return (
    <>
      <BookingsListHeader
        onColumnHeaderClick={sortByColumnHeader}
        headerSize={selectedBookingId ? headerSize.SMALL : headerSize.LARGE}
        sortColumn={sortColumn}
        columnsSortOrderMap={columnsSortOrderMap}
      />
      <BookingsList bookings={bookings} setBookingId={setBookingId} selectedBookingId={selectedBookingId} />
    </>
  );
};

function getStyledVerticalLayout() {
  return styled(VerticalLayout)`
    &.area {
      transition: width 80ms;
    }
    .nav-item {
      color: #007bcd;
      font-size: 16px;
      line-height: 18px;
      font-family: Arial;
      font-style: normal;
    }
  `;
}

function getStyledVerticalDetailLayout() {
  return styled(VerticalLayout)`
    flex: 1 1;
    &.detailArea {
      flex-flow: row;
      margin: 0.2em;
      margin-left: 0.1em;
      justify-content: flex-start;
      // max-height: 95vh;
      // border: 1px solid blue;
    }
  `;
}

function manageWidthTabSection(bookingId) {
  if (!bookingId) {
    return '100%';
  } else {
    return '25%';
  }
}

export { AppointmentsInboxView };
